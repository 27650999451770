
import { WorkOrderRateEntityModel, WorkOrderRateQueryModel } from '@common-src/entity-model/work-order-rate-entity';
import { ICRUDQ } from '@common-src/model/interface';
import { get, post, del, put } from './request';

const URL_PATH = `${WORKORDER_BASE_REQUEST_PATH}/actiFlowEvaluation`;

class WorkOrderRateService implements ICRUDQ<WorkOrderRateEntityModel, WorkOrderRateQueryModel> {
    async create(model: WorkOrderRateEntityModel):Promise<WorkOrderRateEntityModel> {
        const url = `${URL_PATH}/add`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<WorkOrderRateEntityModel> {
        const url = `${URL_PATH}/detail/${modelId}`;
        const res = await get(url);
        return new WorkOrderRateEntityModel().toModel(res);
    }

    async update(model: WorkOrderRateEntityModel):Promise<WorkOrderRateEntityModel> {
        const url = `${URL_PATH}/update`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: WorkOrderRateEntityModel):Promise<WorkOrderRateEntityModel> {
        const url = `${URL_PATH}/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: WorkOrderRateQueryModel, page?: number, limit?: number):Promise<any> {
        const url = `${URL_PATH}/list`;
        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        res.items = _.map(res.items, item => item = new WorkOrderRateEntityModel().toModel(item));
        return res;
        // 不带分页功能的处理方法
        // return _.map(res, item => item = new WorkOrderRateEntityModel().toModel(item));
    }
}

export default new WorkOrderRateService();
