









































import { Component } from 'vue-property-decorator';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import { WorkOrderRateEntityModel, WorkOrderRateQueryModel } from '@common-src/entity-model/work-order-rate-entity';
import WorkOrderRateService from '@common-src/service/work-order-rate';

@Component
export default class WorkOrderRateListComponent extends TableDialogFormComponent<WorkOrderRateEntityModel, WorkOrderRateQueryModel> {
    WorkOrderRateEntityModel = WorkOrderRateEntityModel;

    created() {
        this.initTable({
            service: WorkOrderRateService,
            queryModel: new WorkOrderRateQueryModel(),
            tableColumns: WorkOrderRateEntityModel.getTableColumns()
        });

        this.getList();
    }
}
